import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import {
  USER_CRED,
  GET_PATIENT_ID,
  GET_CLIENT_DATA,
  GET_EHR_APPOINTMENTS,
  GET_NETWORK_DATA,
  GET_BUSINESS,
  USER_PHONE,
  GET_EHR_DIAGNOSTIC_COUNT
} from 'data-layer/queries';
import { UserCred } from 'data-layer/queries/__graphql__/UserCred';
import { GetEHRPatientId } from 'data-layer/queries/__graphql__/GetEHRPatientId';
import { GET_ANY_BUSINESSID } from 'data-layer/queries/geAnyBusinessID';
import {
  GetClientData,
  GetClientDataVariables,
} from 'data-layer/queries/__graphql__/GetClientData';
import { getAnyBusinessID } from 'data-layer/queries/__graphql__/getAnyBusinessID';
import {
  ClientContext,
  urlManager,
  IClientContext,
  defaultClientInfo,
  defaultContext,
  getNationalPhone,
  constants,
  defaultTheme,
  IgetResourceNameParam,
} from 'utils';
import { getLang } from '../utils/i18n';
import { GET_EHR_DIAGNOSTICS } from 'data-layer/queries/getEHRDiagnostics';
import {
  GetEHRAppointments,
  GetEHRAppointmentsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointments';
import {
  GetEHRDiagnostics,
  GetEHRDiagnosticsVariables,
} from 'data-layer/queries/__graphql__/GetEHRDiagnostics';
import { IEHRCounter } from 'data-layer/types';
import { GET_USER_INFO } from 'data-layer/queries/getUser';
import { GetUserInfo, GetUserInfoVariables } from 'data-layer/queries/__graphql__/GetUserInfo';
import { PatientProperties, getPatientInfoFromProfile } from 'data-layer/helpers';
import {
  getNetworkDataVariables,
  getNetworkData,
  getNetworkData_getNetwork_integrationData_ehr,
} from 'data-layer/queries/__graphql__/getNetworkData';
import { getBusiness, getBusinessVariables } from 'data-layer/queries/__graphql__/getBusiness';
import { UserPhone } from 'data-layer/queries/__graphql__/UserPhone';

import moment from 'moment';
import 'moment/locale/ru';
import 'moment/locale/he';
import { GET_EHR_APPOINTMENT_RESULTS } from 'data-layer/queries/getPatientAppointmentResults';
import {
  GetEHRAppointmentResults,
  GetEHRAppointmentResultsVariables,
} from 'data-layer/queries/__graphql__/GetEHRAppointmentResults';
import { GET_EHR_APPOINTMENTS_COUNT } from 'data-layer/queries/getPatientResultsCount';
import { GetEHRAppointmentsCount, GetEHRAppointmentsCountVariables } from 'data-layer/queries/__graphql__/GetEHRAppointmentsCount';
import { GET_EHR_APPOINTMENT_RESULTS_COUNT } from 'data-layer/queries/getPatientAppointmentResultsCount';
import { GetEHRAppointmentResultsCount, GetEHRAppointmentResultsCountVariables } from 'data-layer/queries/__graphql__/GetEHRAppointmentResultsCount';
import { GetEHRDiagnosticsCount, GetEHRDiagnosticsCountVariables } from 'data-layer/queries/__graphql__/GetEHRDiagnosticsCount';
import i18n from 'utils/i18n';
import { Global } from '@emotion/core';
import globalStyles from '../styles';
import { MedMeAPI } from '@gbooking/ts-sdk';
import { BusinessBackofficeConfigurationObject, BusinessInfo } from '@gbooking/schemata/langs/typescript/GBookingCoreV2';
import { useAuth } from './AuthProvider';
import { UserToken } from 'data-layer/types/StrictTokens';

export const ClientContextProvider = ({ children }: { children: React.ReactNode }): JSX.Element => {
  const credData = useQuery<UserCred>(USER_CRED);
  const patientData = useQuery<GetEHRPatientId>(GET_PATIENT_ID);
  const anyBusinessData = useQuery<getAnyBusinessID>(GET_ANY_BUSINESSID, {
    skip: !urlManager.getNetworkId(),
  });
  const businessId = urlManager.getBusinessId() || anyBusinessData.data?.anyBusinessID || '';
  const businessData = useQuery<getBusiness, getBusinessVariables>(GET_BUSINESS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: businessId,
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
  });
  const [backofficeConfiguration, setBackofficeConfiguration] = useState<BusinessBackofficeConfigurationObject>();
  const [generalInfo, setGeneralInfo] = useState<BusinessInfo>();
  useEffect(() => {
    void MedMeAPI.business.getBusinessById(parseInt(businessId)).then((res) => {
      setBackofficeConfiguration(res.backoffice_configuration)
      setGeneralInfo(res.general_info)
    })
  }, [businessId])
  const phoneData = useQuery<UserPhone>(USER_PHONE);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const nationalMode =
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    businessData.data?.getBusinessByID?.info.backoffice_configuration.enablePhoneNationalMode ||
    false;
  const country =
    (phoneData.data?.phoneCountry as string) ||
    (businessData.data?.getBusinessByID?.info.general_info.address[0].country as string) ||
    'RU';

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessCountry = businessData.data?.getBusinessByID?.info.general_info.address[0]
    .country as string;
  const lang = getLang((businessCountry || '').toLocaleLowerCase());

  moment.locale(lang.tag);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessNetworkID: string = generalInfo?.networkID?.toString() ?? '';
  const networkData = useQuery<getNetworkData, getNetworkDataVariables>(GET_NETWORK_DATA, {
    variables: {
      networkID: urlManager.getNetworkId() || businessNetworkID,
      contractID: urlManager.getContractId(),
      contractExtraId: urlManager.getContractExtraId(),
    },
    fetchPolicy: 'cache-and-network',
    skip: !urlManager.getNetworkId() && !businessNetworkID,
  });
  const { tokens, getToken } = useAuth();
  // TODO: remove get old token from apollo client in future
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  const [userToken, setUserToken] = useState<UserToken>(
    getToken(
      networkData.data?.getNetwork?.businessConfiguration.strictLoginInClientCabinet ?? backofficeConfiguration?.strictLoginInClientCabinet, 
      urlManager.getBusinessId(),
      urlManager.getNetworkId(),
    ) ?? { user: '', token: '', expires: '' }
  );
  useEffect(() => {
    setUserToken(
      getToken(
        networkData.data?.getNetwork?.businessConfiguration.strictLoginInClientCabinet ?? backofficeConfiguration?.strictLoginInClientCabinet, 
        urlManager.getBusinessId(),
        urlManager.getNetworkId(),
      ) ?? { user: '', token: '', expires: '' }
    )
  }, [backofficeConfiguration, tokens])

  const user = credData.data?.user ?? userToken.user;
  const token = credData.data?.token ?? userToken.token;
  const expires = credData.data?.expires ?? userToken.expires;
  const patientId = patientData.data?.patientId || '';
  const clientData = useQuery<GetClientData, GetClientDataVariables>(GET_CLIENT_DATA, {
    variables: {
      user,
      token,
      businessID: businessId,
    },
    skip: (!credData.data && !userToken) || !businessId,
  });
  const refetchClient = () => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      clientData.refetch();
    } catch (err) {
      //
    }
  };

  const getEhrUrl = (ehr?: getNetworkData_getNetwork_integrationData_ehr) => {
    if (!ehr) {
      return '';
    }
    const { host, port, path, protocol } = ehr;
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${protocol}//${host}:${port}${path}`;
  };
  const ehrEnabled = urlManager.getNetworkId()
    ? networkData.data?.getNetwork?.integrationData?.ehr?.active || false
    : businessData.data?.getBusinessByID?.integrationData.ehr?.active || false;

  const ehrEndPoint = urlManager.getNetworkId()
    ? getEhrUrl(networkData.data?.getNetwork?.integrationData?.ehr)
    : getEhrUrl(businessData.data?.getBusinessByID?.integrationData.ehr);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const ehrEntities: string[] = urlManager.getNetworkId()
    ? networkData.data?.getNetwork?.integrationData?.ehr.availableEntities || []
    : businessData.data?.getBusinessByID?.integrationData.ehr?.availableEntities || []

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const customTranslates: string = urlManager.getNetworkId()
    ? networkData.data?.getNetwork?.businessConfiguration.newWidgetTheme?.clientCabinetTranslates || '{}'
    : businessData.data?.getBusinessByID?.info.widget_configuration.newWidgetTheme?.clientCabinetTranslates || '{}'

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const translates: any = JSON.parse(customTranslates)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (!!translates && Object.keys(translates).length > 0) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      i18n.addResourceBundle(lang.tag, 'trans', translates, true, true);
    }
  } catch (err) { }
  const userData = useQuery<GetUserInfo, GetUserInfoVariables>(GET_USER_INFO, {
    variables: {
      user,
      token,
    },
    skip: !user || !clientData.data?.findOrCreateClient.id,
  });
  const clientInfoPending = credData.loading || clientData.loading || userData.loading;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  let clientInfo: PatientProperties = defaultClientInfo;
  if (userData.data?.getUserInfo && clientData.data?.findOrCreateClient) {
    clientInfo = getPatientInfoFromProfile(
      userData.data?.getUserInfo,
      clientData.data?.findOrCreateClient,
    );
  }
  const clientId = clientData.data?.findOrCreateClient.id || '';
  const ehrCounter: IEHRCounter = {
    appointment: 0,
    appointmentResult: 0,
    prescription: 0,
    diagnosticReport: 0,
  };
  const context = {
    user,
    token,
    clientId,
    patientId,
    businessID: businessId,
    ehrEndPoint,
  }

  const appEHRCount = useQuery<GetEHRAppointmentsCount, GetEHRAppointmentsCountVariables>(GET_EHR_APPOINTMENTS_COUNT, {
    variables: {
      ...context
    },
    fetchPolicy: 'network-only',
    skip: !patientId || !token || !clientId,
  })

  const appEHRCountResult = appEHRCount.data?.getPatientResultsCount
  const ehrCountResultLoading = !appEHRCount.called || (appEHRCount.called && appEHRCount.loading)

  const appEhrResultCount = useQuery<GetEHRAppointmentResultsCount, GetEHRAppointmentResultsCountVariables>(
    GET_EHR_APPOINTMENT_RESULTS_COUNT,
    {
      variables: {
        ...context
      },
      fetchPolicy: 'network-only',
      skip: !patientId || !token || !clientId || !appEHRCountResult?.support || ehrCountResultLoading
    }
  )
  const appEhrResultsCountResult = appEhrResultCount.data?.getPatientAppointmentResultsCount
  const diagnosticEHRCount = useQuery<GetEHRDiagnosticsCount, GetEHRDiagnosticsCountVariables>(GET_EHR_DIAGNOSTIC_COUNT,
    {
      variables: {
        ...context
      },
      fetchPolicy: 'network-only',
      skip: !patientId || !token || !clientId || !appEHRCountResult?.support || ehrCountResultLoading,
    }
  )
  const diagnosticEHRCountResult = diagnosticEHRCount.data?.getEHRDiagnosticsCount


  const appEHRData = useQuery<GetEHRAppointments, GetEHRAppointmentsVariables>(
    GET_EHR_APPOINTMENTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRCountResult?.support
      // || !appEHRCountResult || !!appEHRCountResult?.support,
    },
  );
  const appEHRDataLoading = !appEHRData.called || (appEHRData.called && appEHRData.loading)
  const appEHRResults = useQuery<GetEHRAppointmentResults, GetEHRAppointmentResultsVariables>(
    GET_EHR_APPOINTMENT_RESULTS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRDataLoading || appEhrResultsCountResult?.support
      // || !appEhrResultsCountResult || !!appEhrResultsCountResult?.support,
    },
  );
  const appEHRResultsLoading = !appEHRResults.called || (appEHRResults.called && appEHRResults.loading)
  const diagnosticEHRData = useQuery<GetEHRDiagnostics, GetEHRDiagnosticsVariables>(
    GET_EHR_DIAGNOSTICS,
    {
      variables: {
        ...context,
        offset: 0,
        size: 200,
      },
      fetchPolicy: 'cache-and-network',
      skip: !patientId || !token || !clientId || ehrCountResultLoading || appEHRDataLoading || appEHRResultsLoading || diagnosticEHRCountResult?.support,
    },
  );

  if (appEHRCountResult?.support) {
    ehrCounter.appointmentResult = appEHRCountResult.count
  } else if (
    appEHRResults.data?.getPatientAppointmentResults.appointmentResults.length
  ) {
    ehrCounter.appointmentResult =
      appEHRResults.data?.getPatientAppointmentResults.appointmentResults.length;
  }
  if (appEhrResultsCountResult?.support) {
    ehrCounter.appointment = appEhrResultsCountResult.count
  } else if (appEHRData.data?.getPatientAppointments.appointments?.length) {
    ehrCounter.appointment = appEHRData.data?.getPatientAppointments.appointments.length || 0;
  }
  if (diagnosticEHRCountResult?.support) {
    ehrCounter.diagnosticReport = diagnosticEHRCountResult.count
  } else if (diagnosticEHRData.data?.getEHRDiagnostics.diagnostics?.length) {
    ehrCounter.diagnosticReport = diagnosticEHRData.data?.getEHRDiagnostics.diagnostics.length || 0;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const businessTimeZone =
    businessData.data?.getBusinessByID?.info?.general_info.timezone || defaultContext.timezone;

  if (nationalMode) {
    clientInfo.phone = getNationalPhone(clientInfo.phone, country);
  }

  let theme = defaultTheme;
  if (networkData.data?.getNetwork?.businessConfiguration?.newWidgetTheme) {
    theme = Object.assign(
      defaultTheme,
      networkData.data?.getNetwork?.businessConfiguration.newWidgetTheme,
    );
  } else if (!urlManager.getNetworkId() && !businessNetworkID) {
    theme = Object.assign(
      defaultTheme,
      businessData.data?.getBusinessByID?.info?.widget_configuration?.newWidgetTheme,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const showExtraFieldsInClientCabinet: boolean =
    businessData.data?.getBusinessByID?.info?.general_info.showExtraFieldsInClientCabinet ??
    defaultContext.showExtraFieldsInClientCabinet;


  const setUserTokenCookie = async () => {
    const cooReq = {
      jsonrpc: '2.0',
      cred: { user, token },
      method: 'misc.set_user_token_cookie',
      params: {},
      id: 1,
    };
    await fetch(ehrEndPoint, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(cooReq),
    });
  }
  // if (patientId) {
  //   void setUserTokenCookie().catch(() => { })
  // }
  const clientContext: IClientContext = {
    clientId,
    clientInfo,
    clientInfoPending,
    patientId,
    businessId,
    networkId: businessNetworkID,
    user,
    token,
    expires,
    ehrCounter,
    ehrCounterSupport: !!appEHRCountResult?.support,
    ehrEnabled,
    ehrEndPoint,
    ehrEntities,
    timezone: businessTimeZone,
    showExtraFieldsInClientCabinet,
    nationalMode,
    country,
    refetchClient,
    businessInfo: businessData.data?.getBusinessByID?.info,
    backofficeConfiguration: backofficeConfiguration,
    generalInfo: generalInfo,
    network: networkData.data?.getNetwork ?? undefined,
    lang: lang || constants.LANGUAGES[0],
    theme,
    getResourceName: defaultContext.getResourceName,
  };

  if (businessData.data?.getBusinessByID?.info.widget_configuration.middleNameSupport) {
    const { middleNameSupport } = businessData.data?.getBusinessByID?.info.widget_configuration;
    clientContext.getResourceName = ({ name, surname, middleName }: IgetResourceNameParam) => {
      if (middleNameSupport && middleName) {
        return `${surname} ${name} ${middleName}`;
      }
      return `${name} ${surname}`;
    };
  }
  return (
    <ClientContext.Provider value={clientContext}>
      <>
        <Global styles={() => globalStyles(theme)} />
        {children}
      </>
    </ClientContext.Provider>
  );
};
