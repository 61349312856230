import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import Login from 'pages/login';
import { UserData } from 'data-layer/queries/__graphql__/UserData';
import { USER_LOGIN_DATA } from 'data-layer/queries';
import SetNewPassword from 'pages/setNewPassword';
import Pages from 'pages';
import ProfileEditScreen from 'pages/profileEditScreen';
import { getDisablePasswordLoginByBackofficeConfiguration } from 'utils/disablePasswordLogin';
import { ClientContext } from 'utils';
import { CircleSpinner } from 'components/CircleSpinner';

export const IsLoggedIn: React.FC = () => {
  const { network, backofficeConfiguration, clientInfo, clientInfoPending, user, token } = useContext(ClientContext);
  const [isSkipProfileScreen, setIsSkipProfileScreen] = useState<boolean>(true);
  const [disablePasswordLogin, setDisablePasswordLogin] = useState<boolean>(
    true
  );
  const { data: userData } = useQuery<UserData>(USER_LOGIN_DATA);
  const { name, surname } = clientInfo;

  useEffect(() => {
    setIsSkipProfileScreen(!!name && !!surname);
  }, [name, surname]);
  useEffect(() => {
    setDisablePasswordLogin(getDisablePasswordLoginByBackofficeConfiguration(backofficeConfiguration, network));
  }, [backofficeConfiguration, network]);

  if (!userData || !(user && token)) {
    return <Login />;
  }

  const { askNewPass, showProfileScreen } = userData;

  const renderComponent = () => {
    if(user && token && !clientInfoPending) {
      if (askNewPass && !disablePasswordLogin) {
        return <SetNewPassword />;
      }
      else if (!isSkipProfileScreen && showProfileScreen) {
        return <ProfileEditScreen />;
      }
      else if (!askNewPass || disablePasswordLogin) {
        return <Pages />;
      }
    }
    return () => <CircleSpinner />;
  };

  return <>{renderComponent()}</>;
};
