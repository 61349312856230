/**
 * Default translation for 'ru_RU' language
 */

// tslint:disable-next-line:variable-name export-name
export const ru_ru = {
  pageTitle: "Кабинет пациента",
  resource: 'Специалист',
  title: {
    authTitle: 'Авторизуйтесь :)',
    changePass: 'Создайте пароль',
    home: 'Мои записи',
    homeEmpty: 'Создайте первую запись',
    profile: 'Ваш профиль, {{ userName }}',
    profileEdit: 'Заполните данные о себе',
    newRecord: 'Создайте запись',
    appointments: '',
    search: 'Найдите то, что вы ищете',
    teleApp: 'Телемед записи',
    futureApp: 'Будущие записи',
    pastApp: 'Архив записей',
  },
  button: {
    ok: 'OK',
    ehr: 'ЭМК',
    back: 'Назад'
  },
  common: {
    picker: {
      doneBtnLabel: 'Выбрать',
      cancelBtnLabel: 'Отменить',
    },
    currency: {
      RUB: 'руб.',
      RUR: 'руб.',
    },
    priceType: {
      equal: '',
      begin_with: 'от',
      average: '',
      end: 'до',
    },
    errors: {
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_MISMATCH:
        'Это время уже успели занять, пожалуйста, выберите другое время.',
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_UNAVAILABLE:
        'Выбранный специалист больше не предоставляет данную услугу. Пожалуйста, выберите другого специалиста',
      RPC_ERROR__APPOINTMENT_CONFIRM_FAILED: 'Выбранное время занято',
      RPC_ERROR__APPOINTMENT_CLIENT_OVERLAPPED:
        'Вы уже записаны на это время. Попробуйте записаться на другое время.',
      RPC_ERROR__APPOINTMENT_CABINET_OVERLAPPED:
        'Выбранный кабинет уже занят. Попробуйте записаться в другой кабинет',
      RPC_ERROR__APPOINTMENT_CPACITY_REACH:
        'Это занятие уже полностью заполнено. Пожалуйста, запишитесь на другое время.',
      RPC_ERROR__APPOINTMENT_RESOURCE_BUSY:
        'Выбранный специалист занят. Пожалуйста, запишитесь на другое время или на другого специалиста.',
      RPC_ERROR__CLIENT_IN_BLACKLIST: 'Для записи свяжитесь, пожалуйста, с администратором',
      RPC_ERROR__APPOINTMENT_MIS_CONFIRM_FAILED:
        'Выбранное время уже занято. Пожалуйста, выберите другое время или специалиста.',
      RPC_ERROR__APPOINTMENT_MIS_RESERVE_FAILED:
        'Выбранное время уже занято. Пожалуйста, выберите другое время или специалиста.',
      RPC_ERROR__APPOINTMENT_MIS_CLIENT_DUPLICATE_RECORD:
        'У вас уже имеется запись в клинике. Необходимо подтвердить свой статус в регистратуре',
      RPC_ERROR__APPOINTMENT_MIS_CLIENT_NOT_FOUND:
        'Клиент с такими данными в клинике не найден. Пожалуйста, проверьте корректность введённых данных.',
      RPC_ERROR_BODY: 'Не можем достучаться до серверов GBooking. Может, пропал интернет?',
      RPC_ERROR__CLIENT_ALREADY_BOOKED: 'У Вас уже есть запись в другое время.',
      RPC_ERROR_TITLE: 'Ой! Простите!',
      SMS_CONFIRM_FAILED: 'Код подтверждения SMS неправильный. Попробуйте еще раз',
      SMS_SEND_FAILED:
        'Отправка SMS не может быть выполнена. Проверьте введенный номер телефона и попробуйте еще раз',
      SMS_CODE_INVALID: 'Код подтверждения SMS неправильный. Попробуйте еще раз',
      JS_NO_SELECTED_RESOURCE: 'Не выбран ни один специалист!',
      JS_CRAC_NOT_ACTIVATED: 'crac not activated',
      AUTH_EMAIL_TAKEN:
        'Такой пользователь уже существует! Деактивируйте его по телефону горячей линии.',
      TIME_TO_CONFIRM_HAS_EXPIRED:
        'Время, отведённое на подтверждение записи, истекло. Попробуйте записаться снова',
      YOU_NEED_MORE_TIME: 'Вам нужно еще время?',
      YOU_NEED_MORE_TIME_SUCCESS: 'Да',
    },
  },
  modals: {
    appointmentCancel: {
      successCancelText: 'Запись успешно отменена',
      failCancelText: 'Не удалось отменить запись.',
      processCancelText: 'Отменяем запись',
      title: 'Что сделать с этой записью?',
      text: `Если отмените, придётся записываться заново. Отменить отмену невозможно. 
      Вы можете перенести запись на другое время.`,
      textTelemed: 'Если отмените, придётся записываться заново. Отменить отмену невозможно.',
      bntCancelText: 'Отменить',
      bntChangeText: 'Перенести',
      bntBackText: 'Вернуться назад',
    },
    ehrInfo: {
      title: 'Электронная медицинская карта',
      content: `Сейчас вы видите историю своих посещений, на которые вы записывались через сайт или приложение.
Как только вы подключите ЭМК ( электронную медицинскую карту ), вы сможете видеть все свои визиты в клинику, в том числе те, на которые вы записались через регистратуру, а также смотреть результаты анализов и исследований, скачивать рецепты.
ЭМК оформляется в клинике автоматически во время первого приема. Если вы были на приёме хотя бы один раз - у вас наверняка есть ЭМК. Мы попробуем сопоставить ваши данные с данными клиники, чтобы найти её автоматически.`,
      neverBeen: 'Если вы ещё ни разу не были на приёме в клинике, ',
      doAppointment: 'запишитесь на него,',
      ehrAccess: ' и вы получите доступ к ЭМК на следующий день после визита к доктору',
      cancelBtn: 'Я ещё не был на приёме',
      confirmBtn: 'У меня есть ЭМК',
    },
  },
  components: {
    phoneInput: {
      placeholder: '+7 (ХХХ) ХХХ-ХХ-ХХ',
    },
    passportIdInput: {
      placeholder: 'XXXX XXXXXX',
    },
    appointmentBlock: {
      cancel: 'Отменить',
      change: 'Перенести',
      open: 'Открыть',
      start: 'Начать',
      infoChange: 'Перенести эту встречу уже нельзя, но можно отменить.',
      infoSoon: 'Вы можете открыть окно телемед-встречи с доктором заранее.',
      infoStarted: 'Доктор уже на связи, начинайте.',
      infoNotification: 'За 10 минут до начала вам придёт напоминание в Смс',
      telemed: 'телемед',
    },
    appointmentInfoBlock: {
      telemedInfo: `Вы можете перенести эту встречу ранее, чем за полчаса до начала. 
      Совет: используйте гарнитуру во время связи, так вас будет лучше слышно (а вам — лучше слышно доктора).`,
      telemedJoinButton: 'Начать приём онлайн',
      telemedCopyLink: 'Скопировать ссылку на конференцию: {{ telemedLink }}',
      telemedLinkInClipboard: 'Ссылка скопирована в буфер обмена',
    },
    topNav: {
      newRecord: 'Новая запись',
      myRecords: 'Главная',
      ehr: 'Медкарта',
      profile: 'Профиль',
      record: 'Записаться',
    },
    TopNavAppFilter: {
      allRecords: 'Все записи',
      futureRecords: 'Будущие',
      pastRecords: 'Прошлые',
      telemedRecords: 'Телемед',
      newRecord: 'Новая запись',
    },
    bottomFilters: {
      title: 'Фильтр записей',
      info:
        'Фильтр помогает скрыть лишние записи, и видеть только те, которые нужны сейчас. По умолчанию фильтр выключен.',
      month: 'Месяц',
      year: 'Год',
      location: 'Филиал',
      service: 'Услуга',
      resource: 'Доктор',
      favorites: 'Показать избранные',
      noData: 'Ничего не найдено',
      cleanFilters: 'Сбросить',
      bntBack: 'Вернуться назад',
      bntApply: 'Применить',
    },
    startRecord: {
      title: 'Записаться на приём',
      clinic: 'В клинику',
      resource: 'К доктору',
      service: 'На специальность',
      waitMessage:
        'Если вы не видите записи, сделанной в клинике или по телефону — подождите, она появится здесь в течение суток.',
    },
    newRecord: {
      restClinic: 'Остальные',
      restResource: 'Остальные',
      restService: 'Остальные',
    },
    auth: {
      bookAnAppointment: 'Записаться на приём',
      phoneNumber: 'Номер вашего телефона:',
      passportId: 'Номер паспорта:',
      password: 'Введите свой пароль:',
      getSmsCode: 'Получить смс с кодом',
      resendSmsCode: 'Смс не приходит. Получить ещё одну',
      smsCode: 'Введите число из смс:',
      firstVisit: 'Кажется, вы здесь в первый раз. Если это не так, проверьте номер телефона.',
      submitButtonLabel: 'Ок',
      forgetPasswordButtonLabel: 'Я не помню пароль, зайду по смс',
      rememberPasswordButtonLabel: 'Вход с паролем',
      weakPassword: 'Введен ненадёжный пароль',
      errorPhone: 'Ошибка, проверьте введенный номер',
      errorPassportId: 'Некорректный номер паспорта. Пожалуйста, проверьте данные.',
      errorSmsCode: 'Ошибка, введен неверный код',
      error: 'Ошибка, проверьте введенные данные',
    },
    newPassword: {
      label: 'Придумайте новый пароль',
      info:
        'Пароль должен содержать как минимум одну цифру, одну заглавную и строчную буквы и быть больше 7 символов',
      submit: 'Начать!',
      error: 'Ошибка, пароль не был изменен',
    },
    confirmation: {
      approve: 'Подтвердить',
      withoutPayment: 'Оплатить в&nbsp;клинике',
      onlinePay: 'Оплатить сейчас',
      ok: 'Ок',
      cancel: 'Отмена',
      retry: 'Выбрать другое время',
      confirmCode: 'Подтвердить код',
      requestSmsCode: 'Получить код',
      notReciveCode: 'Не получили код?',
      sendCodeAgain: 'Выслать код повторно',
      attention: 'Внимание, Вы будете записаны на следующие услуги:',
      // tslint:disable-next-line:max-line-length
      attentionDescription:
        'Если Вы хотите записаться с большим временным интервалом, позвоните по телефону клиники, оператор подберет для Вас подходящее время.',
      minutesShort: 'мин',
      save: 'Сохранить',
    },
    marker: {
      choose: 'Выбрать',
    },
    search: {
      filter: 'фильтр:',
      filters: {
        all: 'Всё',
        locations: 'Только филиалы',
        resources: 'Только докторов',
        taxonomies: 'Только специальности',
      },
      recentListTitle: 'Вы недавно искали:',
      doctorListTitle: 'Ваши доктора',
      inputPlaceholder: 'Введите хотя бы три символа...',
      category: 'Направление',
      resource: 'Специалист',
      business: 'Филиал',
      EmptySearch: 'Ничего не найдено',
    },
    resource: {
      anyAvailable: 'Любой свободный',
    },
    visitsList: {
      closeBtn: 'Закрыть',
      locationTitle: 'Мед. учреждение',
      addressTitle: 'Адрес',
      routBtn: 'Проложить маршрут',
      rescheduleBtn: 'Перенести',
      cancelBtn: 'Отменить',
      moreBtn: 'Подробнее о визите',
      noVisits: 'У вас нет запланированных визитов',
      noVisitsText: 'Чтобы записаться, начните с выбора направления, филиала или доктора',
      noVisitResult: 'Нет результата визита',
      anamnesis: 'Анамнез',
      diagnosis: 'Диагноз',
      medicalExaminationResult: 'Результаты обследования',
      prescriptions: 'Рекомендации',
    },
    misAuth: {
      form: {
        title: 'Введите ваши данные',
        error: 'Совпадения не найдены, проверьте введённые данные',
        name: 'Ведите ваши ФИО',
        insuranceNumber: 'Номер полиса',
      },
      approve: 'Продолжить',
    },
    misSelect: {
      title: 'Для записи страхового клиента выберите тип договора',
      skip: 'Записаться без выбора страхового договора',
    },
    startScreen: {
      confirm: 'Продолжить',
    },
    profileForm: {
      label:
        'Можно пропустить этот шаг, но потом всё равно нужно будет заполнить, чтобы попасть к доктору.',
      labalChangeData: 'Измените свои данные',
      name: 'Имя',
      middleName: 'Отчество',
      surname: 'Фамилия',
      sex: 'Пол',
      birthDate: 'Дата рождения',
      medCard: 'Номер мед карты',
      passportId: 'Паспорт',
      cancelButton: 'Назад',
      cancelSkipButton: 'Пропустить',
      applyButton: 'Дальше',
      applyButtonFind: 'Найти меня',
      insuranceNumber: '№ договора',
      insuranceProgram: 'Название программы',
      insuranceDates: 'Дата действия договора',
    },
    profileInfo: {
      changePhoto: 'Сменить фото',
      changeName: 'Моё имя пишется не так',
      changeGender: 'Здесь ошибка',
      changeBirthDate: 'Это не мой день рождения',
      changePhone: 'У меня новый телефон',
      addEmail: 'Добавьте электронную почту',
      usageAgreement: 'Соглашение об использовании',
      logout: 'Выйти из системы',
      logoutEMK: 'Выйти из ЭМК',
      removeProfile: 'Удалить мой профиль навсегда',
      removeProfileModalTitle: 'Вы собираетесь удалить свой профиль навсегда',
      removeProfileModalDescription: 'Это необратимо. Вы точно хотите это сделать?',
      removeProfileModalCancelBtn: 'Нет',
      removeProfileModalConfirmBtn: 'Хочу',
      changeEmailModalTitle:
        'Добавьте почту, чтобы мы могли экстренно восстановить доступ к данным',
      changeEmailModalDescription: `Если что-то пойдёт не так (например, вы утратите номер своего мобильного телефона), мы сможем восстановить доступ к вашим данным, зная вашу электронную почту.
      Так же, вы сможете получать на почту уведомления о предстоящих визитах, но только если вы захотите.`,
      changeEmailModalInputPlaceholder: 'адрес@почты',
      changeEmailModalCancelBtn: 'Не хочу',
      changeEmailModalConfirmBtn: 'Добавить',
      changePhoneModalTitle: 'Вы собираетесь привязать новый номер мобильного вместо старого',
      changePhoneModalDescription: `Это актуально, если у вас сменился номер.
      По старому больше нельзя будет зайти, как только будет привязан новый.
      Если вы временно утратили доступ к своему номеру, и беспокоитесь, что кто-то может зайти по нему вместо вас, но планируете восстановить доступ, то временно заблокируйте доступ по номеру телефона. Пока он заблокирован, войти в аккаунт можно будет, только зная пароль (по смс зайти будет нельзя). Советуем привязать электронную почту, чтобы можно было восстановить пароль, если что-то пойдет не так.`,
      changePhoneModalLabel: 'Новый номер телефона',
      changePhoneModalCancelBtn: 'Назад',
      changePhoneModalConfirmBtn: 'Изменить номер',
      changePhotoModalTitle: 'Вы собираетесь изменить фото',
      changePhotoModalLabel: 'Добавить\nфото',
      uploadPhoto: 'Загрузить фото',
      changePhotoModalCancelBtn: 'Назад',
      changePhotoModalConfirmBtn: 'Загрузить',
      removePhotoModalLabel: 'Удалить',
      uploadPhotoError: 'Произошла ошибка, попробуйте загрузить фото позже',
    },
    agreement: {
      label: 'Согласен на обработку личных данных',
      info: 'Нажмите, чтобы прочитать подробнее',
      modalClose: 'Закрыть',
      modalConfirm: 'Принять',
    },
    passwordInfo: {
      short: {
        title: 'Пароль слишком короткий',
        text: 'Используйте хотя бы {{passLen}} символов',
      },
      wrong: {
        title: 'Пароль содержит запрещённые символы',
        text:
          'Для пароля можно использовать только буквы латинского алфавита, цифры и символы: `!@#$%^&*()_=+[]{};:"\\|,.',
      },
      weak: {
        title: 'Пароль легко подобрать',
        text: 'Замените одну или две маленькие буквы большими, добавьте цифры',
      },
      strong: {
        title: 'Надёжный пароль',
        text: 'Отлично! Такой подойдёт. Только не забудьте его',
      },
    },
  },
  navigation: {
    profile: {
      history: 'История визитов',
      list: 'История визитов',
      title: 'Профиль',
      logOut: 'Выход',
    },
    home: {},
    appointments: {
      confirm: 'Подтверждение',
      category: 'Выбор категории',
      location: 'Выбор филиала',
      resource: 'Выбор специалиста',
      service: 'Выбор услуги',
      subcategory: 'Выбор подкатегории',
      time: 'Выбор времени',
      cancel: 'Отмена',
      noTime:
        'К сожалению, свободного времени в ближайшие несколько недель по этому специалисту нет. ' +
        'Вы можете вернуться и выбрать другую услугу, специалиста или филиал.',
      unaveilableResource:
        'Чтобы найти этого доктора, нажмите на кнопку ниже, после чего найдите его вручную ' +
        'или поиском по имени в поле поиска.',
      unaveilableResourceBtn: 'Открыть список докторов',
      unaveilableService:
        'Чтобы найти эту услугу, нажмите на кнопку ниже, после чего найдите её вручную ' +
        'или поиском по названию в поле поиска.',
      unaveilableServiceBtn: 'Открыть список услуг',
    },
    chat: {
      title: 'Чат',
    },
    onHome: {
      title: 'На дом',
    },
    MedCard: {
      title: 'Мед. карта',
    },
    login: {
      title: 'Вход',
    },
    pin: {
      title: 'Создание пароля',
    },
    logout: {
      title: 'Выйти',
    },
    maps: {
      title: 'На карте',
    },
    recipes: {
      title: 'Рецепты',
    },
    powered: {
      gbooking: 'Powered by GBooking',
      medMe: 'Powered by Med.me',
    },
    sms: {
      title: 'SMS',
    },
    password: {
      title: 'Пароль',
    },
    agreement: {
      title: 'Соглашение',
    },
    research: {
      title: 'Исследования',
    },
    visits: {
      title: 'Посещения',
    },
    analysis: {
      title: 'Анализы',
    },
  },
  tabbar: {
    appointments: 'Запись',
    profile: 'Профиль',
    chat: 'Чат',
    onHome: 'На дом',
    MedCard: 'Мед. карта',
    research: 'Исследования',
    visits: 'Посещения',
    analysis: 'Анализы',
  },
  screens: {
    home: {
      loadMore: 'Показать ещё...',
      pastVisits: 'Прошлые визиты',
      filter: 'Фильтр',
      future: 'Ближайшие',
      past: 'Архив записей',
    },
    lock: {
      pinError: 'Введён неверный пин',
    },
    ehr: {
      appointment: 'Записи',
      appointmentResult: 'Результаты приемов',
      diagnosticReport: 'Диагнозы и назначения',
      download: 'Скачать файл',
      title: 'Мед. карта',
      customProfileEditLabel:
        'Укажите данные ниже, чтобы мы могли сопоставить ваш профиль с профилем ЭМК',
      inputSurnamePlaceholder: 'Ваша фамилия',
      inputPatronymicPlaceholder: 'Ваше отчество',
      inputNamePlaceholder: 'Ваше имя',
      birthdayPlaceholder: 'Ваша дата рождения',
      loginInfoText: `Укажите свои фамилию и отчество, \n чтобы получить доступ \n к Медицинской карте`,
      ok: 'Ок!',
      hello: `Добрый день, \${name}!`,
      ehrErrorTitle: `Похоже, \n у вас ещё нет \n медицинской карты`,
      ehrError: 'Ошибка запроса к ЭМК, пожалуйста, обновите страницу и попробуйте ещё раз',
      connecting: 'Подключаем к ЭМК, ожидайте',
      ehrNotAvailable:
        'ЭМК в настоящее время недоступна. В скором времени мы вернем доступ. Пожайлуста, сохраняйте терпение.',
      ehrAuthorizationError:
        'Ошибка авторизации! Пожалуйста, проверьте корректность введённых данных!',
      ehrNotFind:
        'ЭМК не найдено! Пожалуйста, обратитесь в клинику для получения доступа к вашей мед карте.',
      ehrConnectionFailed: 'Не удалось установить соединение.',
      ehrDisabled: 'Не удалось установить соединение.',
      notYou: 'Это не вы?',
      loginWithAnotherNumber: 'Зайти под другим номером',
      callReception: `или позвоните в регистратуру по \n телефону \${phone}`,
      checkYourData: 'Или проверьте ваши данные',
      bookFirstAppointment: 'Запишитесь на первый приём',
      loginNumber: 'Вы зашли под номером',
      loginError: 'Заполните все поля',
      introduceYourself: 'Представьтесь, пожалуйста:',
      genderLabel: 'Пол:',
    },
    appointments: {
      form: {
        nameLabel: 'Введите ваши ФИО',
        name: 'Имя',
        namePlaceholder: 'Введите ваше имя',
        surname: 'Фамилия',
        surnamePlaceholder: 'Введите вашу фамилию',
        patronymic: 'Отчество',
        patronymicPlaceholder: 'Введите ваше отчество',
        phone: 'Телефон',
        professtion: 'Профессия',
        insuranceNumber: 'Номер страхового полиса',
        useInsuranceGuaranteeLetter: 'Гарантийное письмо',
        direction: 'Направление',
        address: 'Адрес',
        date: 'Дата',
        dateTime: 'Время и дата',
        total: 'Стоимость',
        doctor: 'Специалист',
        businessType: 'Филиал',
        discount: 'с учетом скидки',
        category: 'Категория',
        subcategory: 'Подкатегория',
        service: 'Услуга',
        select: 'Выберите…',
        time: 'Время',
        emailPlaceholder: 'example@email.ru',
        emailLabel: 'Введите ваш e-mail',
        phoneLabel: 'Введите ваш номер телефона',
        phonePlaceholder: '+7 123 456-78-90',
        addressInfo: {
          notify:
            'В данный момент у данного филиала нет онлайн записи, но вы можете записаться на прием по телефону',
        },
        SearchInputPlaceholder: 'Искать по специалисту, адресу или направлению',
        MedCardSearchInputPlaceholder: 'Доктор, диагноз, лекарство и тд.',
        SingleSearchInputPlaceholder: 'Искать по специалисту или направлению',
        title: 'Завершите запись',
        description: 'Введите свои данные для завершения записи.',
        notice: ' — обязательно для заполнения',
        nameError: 'Некорректный формат',
        emailError: 'Некорректный email',
        fio: 'ФИО',
        genderLabel: 'Ваш пол',
        clientComment: 'Ваш комментарий или пожелание',
        birthdayLabel: 'Ваша дата рождения (дд.мм.гггг)',
        birthdayLabelShort: 'Ваша дата рождения',
        passportLabel: 'Ваш номер паспорта',
        addressLabel: 'Ваш адрес',
        genderMale: 'Мужской',
        genderFemale: 'Женский',
        genderSelect: 'Выберите пол',
        descriptionVisitor: 'Введите данные для завершения записи.',
        emaiVisitorlLabel: 'e-mail для уведомлений',
        genderVisitorLabel: 'Пол пациента',
        visitorName: 'ФИО пациента',
        birthdayVisitorLabel: 'Дата рождения пациента (дд.мм.гггг)',
        birthdayVisitorLabelShort: 'дата рождения пациента',
      },
      tomorrow: 'Завтра',
      today: 'Сегодня',
      phoneClinic: 'Телефон клиники:',
      noAppFound: 'Запись не найдена',
      download: 'Скачать',
      dataLoadedSuccess: 'Данные загружены',
    },
    profile: {
      history: {
        reRegister: 'Записаться повторно',
        edit: 'Изменить',
        cancel: 'Отменить',
        inactive: 'Запись неактивна',
      },
      main: {
        twoFAUserDescription: 'ID Клиента (ЭМК)',
        appointmentBlockTitle: 'Запись к специалисту',
        map: 'Маршрут',
        connectedProfiles: 'ПРИВЯЗАННЫЕ ПРОФИЛИ',
        authorizationWarning: 'Вам необходимо создать запись, чтобы авторизоваться в системе.',
        select: 'Выбрать',
        nearVisits: 'БЛИЖАЙШИЕ ВИЗИТЫ',
        historyOfVisits: 'История визитов',
        recipes: 'Рецепты',
        createNew: 'Создать новую запись',
        createNewInfo: 'У вас пока ещё нет ни одной назначеной записи',
      },
      splash: `Профиль пока в разработке
Скоро он появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    common: {
      splash: {
        btnTitle: 'Написать',
        emailTitle: 'Личный Кабинет Клиента. Форма обратной связи',
        emailBody: `Добрый день!
        Моё обращение:

        Меня зовут:
        Используйте для связи со мной следующий телефон: \${phone}

        Спасибо`,
      },
    },
    recipes: {
      splash: `Модуль рецептов пока в разработке
Скоро он появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    research: {
      ready: 'Готов',
      noReady: 'Не готов',
      status: 'Статус: ',
      results: 'Результаты',
    },
    main: {
      splash: `Лента Акций и Новостей пока в разработке
Скоро она появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    chat: {
      splash: `Чат пока в разработке
Скоро он появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    onHome: {
      splash: `Вызов специалиста на дом пока в разработке
Скоро он появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    login: {
      loginPlaceholder: 'Логин',
      codePlaceholder: 'Код',
      enterBtnTitle: 'Продолжить',
      info: 'Нажимая на кнопку, вы соглашаетесь на обработку ',
      personalData: 'персональных данных',
      inputLabel: 'Введите телефон',
      phoneInputErrorTooltip: 'Проверьте правильность введённого номера',
      phoneInputTooltip:
        'Введите корректный номер телефона, чтобы продолжить. Убедитесь, что у вас уже есть учетная запись в клинике.',
    },
    logout: {
      actiontitle: 'Удаление профиля',
      actionmessage:
        'После совершения этого действия все данные будут удалены, и потребуется пройти активацию приложения заново',
      closeAppMessage: 'Вы действительно хотите закрыть приложение?',
      logOut: 'Удалить профиль',
      cancel: 'Вернуться',
      closeApp: 'Выйти из приложения',
    },
    code: {
      error: 'Вы ввели неправильный код из SMS',
      resend: 'Запросить код снова',
      delay: 'Запросите снова через ',
      noCode: 'Не получили код?',
      second: ' сек.',
      enterCode: 'Введите временный код из SMS:',
    },
    maps: {
      splash: `Показ филиалов на карте пока в разработке
Скоро он появится, но вы можете
нам написать о ваших пожеланиях`,
    },
    pin: {
      enterCode: 'Введите код',
      recoverCode: 'Забыли код?',
      touchIdTitle: 'Подтвердите действие',
      info: 'Создайте свой постоянный пароль ИЛИ подключите TouchID',
    },
    eula: {
      info: 'Прочтите и примите соглашение',
      acceptText: 'Принять и продолжить',
      nextPage: 'Далее',
    },
  },
};
