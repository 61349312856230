/**
 * Default translation for 'en_US' language
 */

// tslint:disable-next-line:variable-name export-name
export const en_us = {
  pageTitle: "Personal cabinet",
  resource: 'Specialist',
  title: {
    authTitle: 'Please log in to continue :)',
    changePass: 'Please create a password',
    home: 'My appointments',
    homeEmpty: 'Create your first appointment',
    profile: 'Your profile, {{ userName }}',
    profileEdit: 'Fill in the missing information about yourself',
    newRecord: '',
    appointments: '',
    search: 'Find what you are looking for',
    teleApp: 'Telemedicine appointments',
    futureApp: 'Future appointments',
    pastApp: 'Appointment archive',
  },
  button: {
    ok: 'OK',
    ehr: 'EMR',
    back: "Back"
  },
  common: {
    picker: {
      doneBtnLabel: 'Select',
      cancelBtnLabel: 'Cancel',
    },
    currency: {
      RUR: '₽',
      USD: 'USD',
      ILS: '₪',
    },
    priceType: {
      equal: '',
      begin_with: 'from',
      average: '',
      end: 'to',
    },
    errors: {
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_MISMATCH:
        'This time slot is already taken; please select different one.',
      RPC_ERROR__APPOINTMENT_RESOURCE_TAXONOMY_UNAVAILABLE:
        'The selected specialist no longer provides this service. Please select different specialist',
      RPC_ERROR__APPOINTMENT_CONFIRM_FAILED: 'Selected time slot is already taken',
      RPC_ERROR__APPOINTMENT_CLIENT_OVERLAPPED:
        'You already have an appointment at this time. Please create an appointment at different time slot.',
      RPC_ERROR__APPOINTMENT_CABINET_OVERLAPPED:
        'Selected cabinet is already taken. Please try booking different cabinet',
      RPC_ERROR__APPOINTMENT_CPACITY_REACH:
        'This class is already fully booked. Please create an appointment at different time slot.',
      RPC_ERROR__APPOINTMENT_RESOURCE_BUSY:
        'Selected specialist is busy at this time. Please select different time or different specialist.',
      RPC_ERROR__CLIENT_IN_BLACKLIST: 'Please contact administrator to create an appointment',
      RPC_ERROR__APPOINTMENT_MIS_CONFIRM_FAILED:
        'Selected time slot is already taken. Please select different time or different specialist.',
      RPC_ERROR__APPOINTMENT_MIS_RESERVE_FAILED:
        'Selected time slot is already taken. Please select different time or different specialist.',
      RPC_ERROR__APPOINTMENT_MIS_CLIENT_DUPLICATE_RECORD:
        "You already have an appointment in this clinic. You need to confirm your status at the clinic's reception desk.",
      RPC_ERROR__APPOINTMENT_MIS_CLIENT_NOT_FOUND:
        'The client with such data was not found in the clinic. Please make sure you have entered correct data.',
      RPC_ERROR_BODY:
        'Cannot access GBooking servers. Please make sure that internet is turned on.',
      RPC_ERROR__CLIENT_ALREADY_BOOKED: 'You already have an appointment at different time.',
      RPC_ERROR_TITLE: 'Ooops! An error occurred…',
      SMS_CONFIRM_FAILED: 'Invalid SMS confirmation code! Please try again',
      SMS_SEND_FAILED:
        'The SMS could not be sent. Please check the phone number you entered and try again',
      SMS_CODE_INVALID: 'Invalid SMS confirmation code! Please try again',
      JS_NO_SELECTED_RESOURCE: 'No specialist selected!',
      JS_CRAC_NOT_ACTIVATED: 'CRAC is not activated',
      AUTH_EMAIL_TAKEN: 'This user already exists! Please call the hotline to deactivate them.',
      TIME_TO_CONFIRM_HAS_EXPIRED:
        'The time limit for appointment confirmation has expired. Please try create an appointment once again',
      YOU_NEED_MORE_TIME: 'Do you need more time?',
      YOU_NEED_MORE_TIME_SUCCESS: 'Yes',
    },
  },
  modals: {
    appointmentCancel: {
      successCancelText: 'Appointment has been cancelled successfully',
      failCancelText: 'Failed to cancel an appointment.',
      processCancelText: 'Cancelling an appointment…',
      title: 'What would you like to do with this appointment?',
      text:
        "If you cancel the appointment, you'll have to create another appointment once again. Cancelling cannot be undone. \n      You can reschedule the appointment for another time.",
      bntCancelText: 'Cancel',
      bntChangeText: 'Move',
      bntBackText: 'Go back',
    },
    ehrInfo: {
      title: 'Electronic Medical Records',
      content:
        "This is the history of your previous visits that had been created using website or mobile application.\nAs soon as you enable Electronic Medical Records (EMR), you will be able to see all your visits to this clinic, including the ones created by the clinic's administrator at the reception desk. Also you'll get access to the results of you lab tests, as well as your e-prescriptions.\nYour EMR will be enabled automatically after your first visit to the clinic. If you visited this clinic at least once, most probably you already have your EMR enabled. We will now try to try to match your data with the one stored in the clinic to find your EMR automatically.",
      neverBeen: 'If you have never been to this clinic before, ',
      doAppointment: 'please create an appointment,',
      ehrAccess: " and you'll get access to your EMR the day after your visit to the clinic",
      cancelBtn: "I haven't had an appointment yet",
      confirmBtn: 'I already have EMR',
    },
  },
  components: {
    appointmentBlock: {
      cancel: 'Cancel',
      change: 'Move',
      open: 'Open',
      start: 'Start',
      infoChange: 'You cannot move this appointment anymore, but instead you can cancel it.',
      infoSoon: 'You can open a telemedicine meeting window with your doctor in advance.',
      infoStarted: 'The doctor is ready and is waiting for you to join, go ahead.',
      infoNotification:
        '10 minutes before the start of the appointment you will receive a reminder via SMS',
      telemed: 'telemedicine',
    },
    appointmentInfoBlock: {
      telemedInfo:
        'You can reschedule this meeting earlier than half an hour before it starts. \n      Tip: Use a headset when communicating so you can be heard better (and you can hear the doctor better, too).',
      telemedJoinButton: 'Start an appointment online',
      telemedCopyLink: 'Copy the conference link: {{ telemedLink }}',
      telemedLinkInClipboard: 'The link has been copied to the clipboard',
    },
    topNav: {
      newRecord: 'Create new appointment',
      myRecords: 'Appointments',
      ehr: 'Medical card',
      profile: 'Profile',
      record: 'Book now',
    },
    TopNavAppFilter: {
      allRecords: 'All appointments',
      futureRecords: 'Future appointments',
      pastRecords: 'Appointment history',
      telemedRecords: 'Telemedicine appointments',
      newRecord: 'New appointment',
    },
    bottomFilters: {
      title: 'Appointment filter',
      info:
        'This filter helps you hide unnecessary appointments, and see only the ones you need now. By default, the filter is off.',
      month: 'Month',
      year: 'Year',
      location: 'Location',
      service: 'Service',
      resource: 'Doctor',
      favorites: 'Show favorites',
      noData: 'Nothing found',
      cleanFilters: 'Discard',
      bntBack: 'Go back',
      bntApply: 'Apply',
    },
    startRecord: {
      title: 'Create a new appointment:',
      clinic: 'By location',
      resource: 'By doctor',
      service: 'By service',
      waitMessage:
        'If you do not see the appointment created in the clinic or arranged by phone — please wait, it will appear here within 24 hours.',
    },
    newRecord: {
      restClinic: 'Select location',
      restResource: 'Select doctor',
      restService: 'Select service',
    },
    auth: {
      bookAnAppointment: 'Book an appointment',
      phoneNumber: 'Your phone number:',
      passportId: 'ID number:',
      password: 'Enter the new password:',
      getSmsCode: 'Receive SMS with the code',
      resendSmsCode: 'There is no SMS coming. Get another one',
      smsCode: 'Please enter the number from the SMS:',
      firstVisit: 'You seem to be here for the first time. If not, check the phone number.',
      submitButtonLabel: 'OK',
      forgetPasswordButtonLabel: "I don't remember the password, would like to log in via SMS",
      rememberPasswordButtonLabel: 'I remembered my password',
      weakPassword: "The password you've entered is unsecure",
      errorPhone: "An error occurred, please check the number you've entered",
      errorPassportId: "Invalid ID number. Please check the information.",
      errorSmsCode: 'An error occurred, wrong code was entered',
      error: "An error occurred, please check the data you've entered",
    },
    buttons: {
      sendSmsAgain: 'There is no SMS coming. Get another one',
    },
    newPassword: {
      label: 'Think up a new password',
      info:
        'The password must contain at least one digit, one uppercase and lowercase letters, and be longer than 7 characters',
      submit: "Let's start!",
      error: "An error occurred, your password hasn't been changed",
    },
    confirmation: {
      approve: 'Confirm',
      withoutPayment: 'Pay at&nbsp;the&nbsp;clinic',
      onlinePay: 'Pay now',
      ok: 'OK',
      cancel: 'Cancel',
      retry: 'Select different time',
      confirmCode: 'Confirm code',
      requestSmsCode: 'Get code',
      notReciveCode: "Didn't get the code?",
      sendCodeAgain: 'Re-send the code',
      attention: 'An appointment will be created for you with the following services:',
      attentionDescription:
        'If you want to make an appointment with a longer time interval, please call the clinic, and the operator will find a suitable time for you.',
      minutesShort: 'minutes',
      save: 'Save',
    },
    marker: {
      choose: 'Select',
    },
    search: {
      filter: 'filter:',
      filters: {
        all: 'All',
        locations: 'Only locations',
        resources: 'Only doctors',
        taxonomies: 'Only specializations',
      },
      recentListTitle: 'You searched recently for:',
      doctorListTitle: 'Your doctors',
      inputPlaceholder: 'Please enter at least 3 characters…',
      category: 'Service',
      resource: 'Doctor',
      business: 'Location',
      EmptySearch: 'Nothing found',
    },
    resource: {
      anyAvailable: 'Any available doctor',
    },
    visitsList: {
      closeBtn: 'Close',
      locationTitle: 'Clinic',
      addressTitle: 'Address',
      routBtn: 'Get directions',
      rescheduleBtn: 'Move',
      cancelBtn: 'Cancel',
      moreBtn: 'More details about the visit',
      noVisits: 'You have no scheduled appointments',
      noVisitsText: 'To create an appointment, start by choosing a service, location, or doctor',
      noVisitResult: 'No visit results',
      anamnesis: 'Anamnesis',
      diagnosis: 'Diagnosis',
      medicalExaminationResult: 'Examination results',
      prescriptions: 'Recommendations',
    },
    misAuth: {
      form: {
        title: 'Enter your information',
        error: 'No matches found, please check the data you entered',
        name: 'Please enter your full name',
        insuranceNumber: 'Your insurance policy number',
      },
      approve: 'Continue',
    },
    misSelect: {
      title:
        'To create an appointment for an insurance client, please select the insurance policy type',
      skip: 'Create an appointment without selecting an insurance policy',
    },
    startScreen: {
      confirm: 'Continue',
    },
    profileForm: {
      label:
        'You can skip this step for now, but then you still need to fill it out to finalize your medical appointment creation.',
      labalChangeData: 'Change your data',
      name: 'First name',
      middleName: 'Middle name or Patronymic',
      surname: 'Last name',
      sex: 'Sex',
      birthDate: 'Date of birth',
      medCard: 'Medical card ID',
      cancelButton: 'Cancel',
      cancelSkipButton: 'Skip',
      applyButton: 'Save changes',
      applyButtonFind: 'Find me',
    },
    profileInfo: {
      changePhoto: 'Change photo',
      changeName: 'My name is spelled differently',
      changeGender: "Let's change that",
      changeBirthDate: 'This is not my date of birth',
      changePhone: "I've got a new phone number",
      addEmail: 'Add an email',
      usageAgreement: 'User agreement',
      logout: 'Log out',
      logoutEMK: 'Log out from Electronic Medical Records',
      removeProfile: 'Delete my profile permanently',
      removeProfileModalTitle: 'You are about to delete your profile permanently',
      removeProfileModalDescription:
        'This action cannot be undone. Are you sure you want to do this?',
      removeProfileModalCancelBtn: 'No',
      removeProfileModalConfirmBtn: 'Yes, I do',
      changeEmailModalTitle:
        'Please add your email so that we could restore access to the data in case of emergency',
      changeEmailModalDescription:
        'If something goes wrong (for example, if you lose your mobile phone number), we can restore access to your data by knowing your email.\n      Also, you will be able to receive email notifications about your upcoming visits, but only if you want to.',
      changeEmailModalInputPlaceholder: 'address@domain',
      changeEmailModalCancelBtn: 'Cancel',
      changeEmailModalConfirmBtn: 'Add',
      changePhoneModalTitle: 'You are going to link a new mobile number instead of the old one',
      changePhoneModalDescription:
        'This is relevant if you have changed your number.\n      You can no longer use the old one as soon as the new one is linked.\n      If you have temporarily lost access to your number and are worried that someone might access it instead of you, but plan to regain access, then please temporarily block access by phone number. As long as it is locked, you will only be able to log in to your account if you know your password (you will not be able to log in via SMS). We advise you to link your email so that you can recover your password if something goes wrong.',
      changePhoneModalLabel: 'New phone number',
      changePhoneModalCancelBtn: 'Go back',
      changePhoneModalConfirmBtn: 'Change phone number',
      changePhotoModalTitle: 'You are about to change your photo',
      changePhotoModalLabel: 'Add\nphoto',
      uploadPhoto: 'Upload photo',
      changePhotoModalCancelBtn: 'Cancel',
      changePhotoModalConfirmBtn: 'Upload',
      removePhotoModalLabel: 'Delete',
      uploadPhotoError: 'An error occurred, please try again later',
    },
    agreement: {
      label: 'I hereby give my consent to the processing of my personal data',
      info: 'Click here to read more',
      modalClose: 'Close',
      modalConfirm: 'Accept',
    },
    passwordInfo: {
      short: {
        title: 'Password is too short',
        text: 'Please use at least {{passLen}} characters',
      },
      wrong: {
        title: "The password you've entered contains prohibited characters",
        text:
          'You only can use Latin letters, digits and the following symbols: `!@#$%^&*()_=+[]{};:"\\|,.',
      },
      weak: {
        title: 'Your password is easy to guess',
        text:
          'Please replace one or two small letters with the capital letters, try adding numbers',
      },
      strong: {
        title: 'Strong password!',
        text: "Great, that one will do. Please don't forget it.",
      },
    },
  },
  navigation: {
    profile: {
      history: 'History of visits',
      list: 'History of visits',
      title: 'Profile',
      logOut: 'Log out',
    },
    home: {},
    appointments: {
      confirm: 'Confirmation',
      category: 'Category selection',
      location: 'Location selection',
      resource: 'Doctor selection',
      service: 'Service selection',
      subcategory: 'Subcategory selection',
      time: 'Time selection',
      cancel: 'Cancel',
      noTime:
        'Unfortunately, there is no free time within the next few weeks for this specialist. You can go back and choose another service, specialist, or location.',
      unaveilableResource:
        'To find this doctor, click on the button below, then find him manually or search by name in the search box.',
      unaveilableResourceBtn: 'Open the list of doctors',
      unaveilableService:
        'To find this service, click on the button below, then find it manually or search by name in the search box.',
      unaveilableServiceBtn: 'Open the list of services',
    },
    chat: {
      title: 'Chat',
    },
    onHome: {
      title: 'House call',
    },
    MedCard: {
      title: 'Med Records',
    },
    login: {
      title: 'Login',
      backToLoginWarning: 'The 5-minute validity period for the one-time password has expired.',
      backToLoginWarningMsg: 'Please re-login.',
    },
    pin: {
      title: 'Password creation',
    },
    logout: {
      title: 'Log out',
    },
    maps: {
      title: 'Map view',
    },
    recipes: {
      title: 'E-Prescriptions',
    },
    powered: {
      gbooking: 'Powered by GBooking',
      medMe: 'Powered by Med.me',
    },
    sms: {
      title: 'SMS',
    },
    password: {
      title: 'Password',
    },
    agreement: {
      title: 'Agreement',
    },
    research: {
      title: 'Examinations',
    },
    visits: {
      title: 'Visits',
    },
    analysis: {
      title: 'Lab tests',
    },
  },
  tabbar: {
    appointments: 'Online booking',
    profile: 'Profile',
    chat: 'Chat',
    onHome: 'House call',
    MedCard: 'Med Records',
    research: 'Examinations',
    visits: 'Visits',
    analysis: 'Lab tests',
  },
  screens: {
    home: {
      loadMore: 'Show more…',
      pastVisits: 'Last visits',
      filter: 'Filter',
      past: 'Archive of records',
      future: 'Close',
    },
    lock: {
      pinError: "You've entered incorrect PIN",
    },
    ehr: {
      title: 'Med Records',
      customProfileEditLabel:
        'Provide the information below so we can match your profile with your electronic medical records profile',
      inputSurnamePlaceholder: 'Your last name',
      inputPatronymicPlaceholder: 'Your middle name (patronymic)',
      inputNamePlaceholder: 'Your first name',
      birthdayPlaceholder: 'Your date of birth',
      loginInfoText:
        'Please specify your last name and middle name (patronymic), \n to get access \n to your Med Records',
      ok: 'OK!',
      hello: 'Hello, ${name}!',
      ehrErrorTitle: "It looks like, \n you don't have \n medical records yet",
      ehrError: 'Error in requesting the Electronic medical records',
      ehrNotAvailable:
        'Med Records are currently unavailable. We will return access shortly. Please be patient.',
      ehrAuthorizationError: 'Login error! Please check if the data you entered is correct!',
      ehrNotFind:
        'Med Records not found! Please contact the clinic to get access to your medical records.',
      ehrConnectionFailed: 'Failed to connect.',
      ehrDisabled: 'Failed to connect.',
      notYou: "That wasn't you?",
      loginWithAnotherNumber: 'Log in under different phone number',
      callReception: "or get in touch with the clinic's reception desk \n by calling ${phone}",
      checkYourData: "Or check the data you've entered",
      bookFirstAppointment: 'Create your first appointment',
      loginNumber: "You're logged in with number",
      loginError: 'Please fill in all fields',
      introduceYourself: 'Please introduce yourself.',
      genderLabel: 'Sex:',
    },
    appointments: {
      form: {
        nameLabel: 'Please enter your full name',
        name: 'First name',
        namePlaceholder: 'Please enter your first name',
        surname: 'Last name',
        surnamePlaceholder: 'Please enter your last name',
        patronymic: 'Middle name (patronymic)',
        patronymicPlaceholder: 'Please enter your middle name (patronymic)',
        phone: 'Phone number',
        professtion: 'Profession',
        insuranceNumber: 'Your insurance policy number',
        useInsuranceGuaranteeLetter: 'Letter of guarantee',
        direction: 'Specialization',
        address: 'Address',
        date: 'Date',
        dateTime: 'Date and time',
        total: 'Cost',
        doctor: 'Specialist',
        businessType: 'Location',
        discount: 'with discount',
        category: 'Category',
        subcategory: 'Subcategory',
        service: 'Service',
        select: 'Please select…',
        time: 'Time',
        emailPlaceholder: 'example@email.com',
        emailLabel: 'Enter your email',
        phoneLabel: 'Enter your phone number',
        phonePlaceholder: '+1 123 456-78-90',
        addressInfo: {
          notify:
            'This location does not currently accept online appointment creation. You can make an appointment by phone.',
        },
        SearchInputPlaceholder: 'Search by specialist, location or service',
        MedCardSearchInputPlaceholder: 'Doctor, diagnosis, prescription etc',
        SingleSearchInputPlaceholder: 'Search by specialist or service',
        title: 'Complete appointment creation',
        description: 'Enter your information to complete appointment creation',
        notice: ' — REQUIRED',
        nameError: 'Incorrect format',
        emailError: 'Incorrect email',
        fio: 'Full name',
        genderLabel: 'Sex',
        clientComment: 'Your comment, suggestion or idea',
        birthdayLabel: 'Your date of birth (mm.dd.yyyy)',
        birthdayLabelShort: 'Your date of birth',
        passportLabel: 'Your passport ID',
        addressLabel: 'Your address',
        genderMale: 'Male',
        genderFemale: 'Female',
        genderSelect: 'Select sex',
        descriptionVisitor: 'Enter data to complete appointment creation.',
        emaiVisitorlLabel: 'e-mail for notifications',
        genderVisitorLabel: "Patient's sex",
        visitorName: 'Full name of the patient',
        birthdayVisitorLabel: "Patient's date of birth (mm.dd.yyyy)",
        birthdayVisitorLabelShort: "Patient's date of birth",
      },
      tomorrow: 'Tomorrow',
      today: 'Today',
      phoneClinic: "Clinic's phone number:",
      noAppFound: 'Appointment not found',
      download: 'Download',
      dataLoadedSuccess: 'Data uploaded',
      home: {
        btnsSelect: 'Select',
        visits: 'My visits',
        newFrom: {
          location: 'Select clinic location',
          profession: 'Select specialty',
          resource: 'Select doctor',
        },
        startHint: 'Start appointment creation:',
        errorSheetTitle: "We've got troubles connecting to the internet!",
        errorSheetMessage:
          'Please make sure your internet connection is up and running, then try again.',
        reloadData: 'Reload data',
        cancel: 'Cancel',
        recentVisites: 'My last visits',
        startRecordingMessage: 'What would you like to start you booking from?',
        mainButton: {
          profession: {
            title: 'Specialities',
          },
          location: {
            title: 'Locations',
          },
          resource: {
            title: 'Specialist',
          },
        },
      },
      category: {
        search: 'Search by specialty',
      },
      splitInsuranceClient: {
        insuranceClient: 'I am a patient with an insurance policy',
        commercialClient: 'I will pay for the appointment by myself',
        return: 'Return',
        message: 'To make an appointment, please call',
      },
      subcategory: {
        title: 'Select specialty',
        search: 'Search by subcategories',
        skip: 'Skip to service selection',
      },
      service: {
        title: 'Select specialty',
        search: 'Search by specialty',
        from: 'from',
        to: 'to',
        beginWith: 'from',
        showDetails: 'Details',
        hideDetails: 'Hide description',
      },
      resources: {
        search: 'Search by specialist name and specialty',
        anyBtn: 'Any doctor',
        anyOverlay: 'Any doctor',
        all: 'All doctors',
        favorites: 'Favorite doctors',
        available: 'Available from ',
        onlyCall: 'Set appointment by phone',
        experience: 'Experience',
        notAvailable: 'Online appointment N/A',
        clinic: 'Clinic',
        availableAddress: 'Doctor is available at this location:',
        about: 'About',
        nextAvailable: 'Next available date is ',
        book: 'Book',
        desriptionTitle: 'DOCTOR INFO',
        closeBtn: 'Close',
      },
      address: {
        search: 'Search by address',
        listOption: 'Nearby',
        mapOption: 'Map view',
        anyOverlay: 'Any clinic',
        offline: 'Online booking is not available',
        measure: 'km',
        list: 'List view',
        map: 'Map view',
        book: 'Make an appointment',
        title: 'Select clinic',
        disable: 'Online booking is not available',
      },
      time: {
        businessError:
          'Sorry, but online booking is not configured yet for the selected clinic. You can go back and select different clinic.',
        error:
          'Sorry, but there are no available doctors for the selected specialty within upcoming few weeks. You can go back and select different specialty or location.',
        networkErrorTitle: 'An error occurred while loading data.',
        networkErrorMessage: 'Please make sure that internet is up and running, then try again.',
        ok: 'OK',
        showAvailableSlots: 'Show busy timeslots',
        errorResourceSlots:
          'Sorry, but there are no available timeslots for the selected doctor, we will show you available timeslots of another worker, instead',
        showDescription: "Show specialist's description",
        hideDescription: 'Hide description',
      },
      confirm: {
        total: 'Total',
        smsCodeHint:
          'Please complete this form to verify your phone number. We will send confirmation code to your phone number.',
        smsCodePlaceholder: 'Enter the code received via SMS',
        phonePlaceholder: 'Enter your phone number',
        birthdatePlaceholder: 'mm.dd.yyyy',
        done: 'Done',
        doneDescription: 'Your appointment has been successfully created',
        codeErrorTitle: 'You have entered incorrect code',
        phoneErrorTitle: 'You have entered incorrect phone number',
        codeErrorDescription: 'Please enter correct code',
        phoneErrorDescription: 'Correct format: +11231234567',
        thankYou: 'Your appointment has been successfully created! Stay healthy!',
        newRecord: 'Create another appointment',
        agreeText:
          'By hitting Confirm button below and opting in this checkbox, I&nbsp;agree to receive SMS notifications, as well as I provide consent&nbsp;to&nbsp;the&nbsp;processing of my personal data',
        agreeLink: 'Read more on this',
      },
      disable: {
        message: 'Online appointment creation is currently not available',
        text:
          "We are sorry for the inconvenience caused, but online booking\n         is currenly out of operation in this business.<br /></br>\n         To make an appointment, please call: </br>\n        <a href='tel:${cleanedPhone}'>${phone}</a>",
      },
    },
    profile: {
      history: {
        reRegister: 'Create another appointment',
        edit: 'Edit',
        cancel: 'Cancel',
        inactive: 'Appointment creation is inactive',
      },
      main: {
        twoFAUserDescription: 'Patient ID (EMR)',
        appointmentBlockTitle: 'Appointment with a specialist',
        map: 'Directions',
        connectedProfiles: 'LINKED PROFILES',
        authorizationWarning: 'You need to create an appointment in order to log in to the system.',
        select: 'Select',
        nearVisits: 'UPCOMING APPOINTMENTS',
        historyOfVisits: 'History of visits',
        recipes: 'E-Prescriptions',
        createNew: 'Create new appointment',
        createNewInfo: "You don't have any appointments yet",
      },
      splash:
        'Profile page is currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    common: {
      splash: {
        btnTitle: 'Leave a message',
        emailTitle: 'Patient Portal. Feedback form',
        emailBody:
          'Hi!\n        My message:\n\n        My name is:\n        Please use the following phone number to get in touch with me: ${phone}\n\n        Thank you',
      },
    },
    recipes: {
      splash:
        'E-Prescriptions module is currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    research: {
      ready: 'Ready',
      noReady: 'Not ready yet',
      status: 'Status: ',
      results: 'Results',
    },
    main: {
      splash:
        'News Feed module is currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    chat: {
      splash:
        'Chat is currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    onHome: {
      splash:
        'House calls are currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    login: {
      loginPlaceholder: 'Login',
      codePlaceholder: 'Code',
      enterBtnTitle: 'Continue',
      info: 'By clicking this button you provide consent with the processing ',
      personalData: 'of your personal data',
      inputLabel: 'Enter phone number',
      phoneInputErrorTooltip: 'Check that the phone number you entered is correct',
      phoneInputTooltip:
        'Please enter correct phone number to proceed. Make sure that you already have an account in this Clinic.',
    },
    logout: {
      actiontitle: 'Deleting a profile',
      actionmessage:
        'After this action, all data will be deleted and you will need to activate the application again',
      closeAppMessage: 'Are you sure you want to close the app?',
      logOut: 'Yes, please delete my profile',
      cancel: 'Cancel',
      closeApp: 'Exit',
    },
    code: {
      error: 'You entered an incorrect code from the SMS',
      resend: 'Request code again',
      delay: 'Request again in ',
      noCode: "Didn't receive the code?",
      second: ' s.',
      enterCode: 'Enter the temporary code from the SMS:',
    },
    maps: {
      splash:
        'Map view is currently under development\nIt will be available soon, but you can\ncontact us now and share your ideas',
    },
    pin: {
      enterCode: 'Enter the code',
      recoverCode: 'Forgot the code?',
      touchIdTitle: 'Confirm the action',
      info: 'Please create your permanent password OR make use of TouchID',
    },
    eula: {
      info: 'Please read and accept the following agreement',
      acceptText: 'Accept and continue',
      nextPage: 'Next',
    },
  },
};
