import React, { createContext, useContext, useState, useEffect } from 'react';
import { StrictTokens, UserToken } from 'data-layer/types/StrictTokens';

interface AuthContextType {
  tokens: StrictTokens | null;
  addToken: (
    token: UserToken,
    strictLogin?: boolean,
    businessId?: string,
    networkId?: string,
  ) => void;
  removeToken: (
    strictLogin?: boolean,
    businessId?: string,
    networkId?: string,
  ) => void;
  getToken: (
    strictLogin?: boolean,
    businessId?: string,
    networkId?: string,
  ) => UserToken | undefined;
}

const AuthContext = createContext<AuthContextType | null>(null);

export const AuthProvider: React.FC = ({ children }) => {
  const [tokens, setTokens] = useState<StrictTokens>(() => {
    const storedTokens = localStorage.getItem('tokens');
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return storedTokens ? JSON.parse(storedTokens) : {
      notStrictToken: { user: '', token: '', expires: '' },
      businessTokens: {},
      networkTokens: {},
    };
  });

  useEffect(() => {
    if (tokens) {
      localStorage.setItem('tokens', JSON.stringify(tokens));
    }
  }, [tokens]);

  const addToken = (
    token: UserToken, strictLogin?: boolean, businessId?: string, networkId?: string
  ) => {
    if (strictLogin) {
      if (businessId) {
        setTokens((prev) => ({
          ...prev,
          businessTokens: { ...prev.businessTokens, [businessId]: token },
        }));
      } else if (networkId) {
        setTokens((prev) => ({
          ...prev,
          networkTokens: { ...prev.networkTokens, [networkId]: token },
        }));
      }
    } else {
      setTokens((prev) => ({
        ...prev,
        notStrictToken: token,
      }));
    }
  };

  const removeToken = (strictLogin?: boolean, businessId?: string, networkId?: string) => {
    if (strictLogin) {
      if (businessId) {
        setTokens((prev) => {
          const { [businessId]: _, ...rest } = prev?.businessTokens || {};
          return { ...prev, businessTokens: rest };
        });
      } else if (networkId) {
        setTokens((prev) => {
          const { [networkId]: _, ...rest } = prev?.networkTokens || {};
          return { ...prev, networkTokens: rest };
        });
      }
    } else {
      setTokens((prev) => ({
        ...prev,
        notStrictToken: undefined,
      }));
    }
  };

  const getToken = (strictLogin?: boolean, businessId?: string, networkId?: string): UserToken | undefined => {
    if (strictLogin) {
      if (businessId) {
        return tokens.businessTokens[businessId];
      }
      if (networkId) {
        return tokens.networkTokens[networkId];
      }
    }
    return tokens.notStrictToken;
  };

  return (
    <AuthContext.Provider value={{ tokens, addToken, removeToken, getToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
