import React, { useContext, useState } from 'react';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { gql, ApolloError } from 'apollo-boost';
import { navigate } from '@reach/router';
import { LoginPasswordForm, LoginSmsForm, Loading, PageContainer, WidgetModal } from '../components';
import * as LoginTypes from './__graphql__/Login';
import * as RequestSmsCode from './__graphql__/RequestSmsCode';
import * as ConfirmSmsCode from './__graphql__/ConfirmSmsCode';
import { paths } from 'utils/routing';
import { ClientContext, urlManager } from 'utils';
import {
  GetBusinessInfo,
  GetBusinessInfoVariables,
} from 'data-layer/queries/__graphql__/GetBusinessInfo';
import { GET_BUSINESS_INFO, USER_PHONE } from 'data-layer/queries';
import {
  SetPhoneCountry,
  SetPhoneCountryVariables,
} from 'data-layer/mutations/__graphql__/SetPhoneCountry';
import { SET_PHONE_COUNTRY } from 'data-layer/mutations/PhoneCountry';
import { UserPhone } from 'data-layer/queries/__graphql__/UserPhone';
import { useTranslation } from 'react-i18next';
import { contentCSS } from 'styles';
import { useAuth } from 'components/AuthProvider';

export const LOGIN_USER = gql`
  mutation Login($phone: String!, $password: String!, $country: String!) {
    login(phone: $phone, password: $password, country: $country) @client {
      token
      user
      expires
      askNewPass
    }
  }
`;

export const REQUEST_SMS_CODE = gql`
  mutation RequestSmsCode($phone: String!, $country: String!, $passportId: String!, $lang: String!) {
    requestSmsCode(phone: $phone, country: $country, passportId: $passportId, lang: $lang) @client {
      token
    }
  }
`;

export const CONFIRM_SMS_CODE = gql`
  mutation ConfirmSmsCode($token: String!, $code: String!) {
    confirmSmsCode(token: $token, code: $code) @client {
      token
      user
      expires
      askNewPass
    }
  }
`;

export default function Login(): JSX.Element {
  const { t } = useTranslation();
  const [showSmsAuth, switchAuth] = useState(true);
  const [smsToken, setSmsToken] = useState('');
  const [phoneString, phoneStringInput] = useState('');
  const [passportIdString, setPassportIdString] = useState('');
  const [country, setCountry] = useState('');
  const clientContext = useContext(ClientContext);
  const { theme } = clientContext;
  const phoneData = useQuery<UserPhone>(USER_PHONE);
  if (phoneData.data?.phoneCountry && phoneData.data?.phoneCountry !== country) {
    setCountry(phoneData.data?.phoneCountry);
  }
  const { addToken } = useAuth();
  const [login, { loading, error: loginError }] = useMutation<
    LoginTypes.Login,
    LoginTypes.LoginVariables
  >(LOGIN_USER, {
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
    onCompleted({ login: response }) {
      const queryString = urlManager.getQueryString();
      addToken(
        { user: response?.user ?? '', token: response?.token ?? '', expires: response?.expires ?? '' },
        clientContext.network?.businessConfiguration.strictLoginInClientCabinet ?? clientContext.backofficeConfiguration?.strictLoginInClientCabinet,
        urlManager.getBusinessId(),
        urlManager.getNetworkId(),
      )
      if (!response?.askNewPass) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        navigate(`${paths.homePath}?${queryString}`);
      }
    },
  });
  const [setPhoneCountry] = useMutation<SetPhoneCountry, SetPhoneCountryVariables>(
    SET_PHONE_COUNTRY,
  );
  const [confirmSmsCode, ConfirmSmsCodeStatus] = useMutation<
    ConfirmSmsCode.ConfirmSmsCode,
    ConfirmSmsCode.ConfirmSmsCodeVariables
  >(CONFIRM_SMS_CODE, {
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
    onCompleted({confirmSmsCode: response}) {
      addToken(
        { user: response?.user ?? '', token: response?.token ?? '', expires: response?.expires ?? '' },
        clientContext.network?.businessConfiguration.strictLoginInClientCabinet ?? clientContext.backofficeConfiguration?.strictLoginInClientCabinet,
        urlManager.getBusinessId(),
        urlManager.getNetworkId(),
      )
    },
  });

  const [requestSmsCode, RequestSmsCodeStatus] = useMutation<
    RequestSmsCode.RequestSmsCode,
    RequestSmsCode.RequestSmsCodeVariables
  >(REQUEST_SMS_CODE, {
    // eslint-disable-next-line no-shadow
    onCompleted({ requestSmsCode }) {
      if (requestSmsCode && requestSmsCode.token) {
        // save token and code to state
        setSmsToken(requestSmsCode.token);
      }
    },
    onError(err: ApolloError) {
      // eslint-disable-next-line no-console
      console.log('%s', err.message);
    },
  });
  const smsCodeError = RequestSmsCodeStatus.error || ConfirmSmsCodeStatus.error;

  const switchAuthToggle = () => {
    delete ConfirmSmsCodeStatus.error;
    delete RequestSmsCodeStatus.error;
    switchAuth(!showSmsAuth);
  };
  const phoneStringChange = (phone: string, phoneCountry?: string) => {
    phoneStringInput(phone);
    if (phoneCountry && phoneCountry !== country) {
      setCountry(phoneCountry);
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      setPhoneCountry({ variables: { country: phoneCountry } });
    }
  };

  const generalInfoData = useQuery<GetBusinessInfo, GetBusinessInfoVariables>(GET_BUSINESS_INFO, {
    variables: {
      ID: clientContext.businessId,
    },
    skip: !clientContext.businessId,
  });
  const businessInfo = generalInfoData?.data?.getBusinessInfo;
  if (loading || RequestSmsCodeStatus.loading || ConfirmSmsCodeStatus.loading || !businessInfo) {
    return (
      <PageContainer title={t('title.authTitle')} layoutClassName="login" contentCSS={contentCSS}>
        <Loading theme={theme} />
      </PageContainer>
    );
  }

  return (
    <>
      {showSmsAuth ? (
        <LoginSmsForm
          requestSmsCode={requestSmsCode}
          confirmSmsCode={confirmSmsCode}
          phone={phoneString}
          passportId={passportIdString}
          smsSent={!!smsToken}
          smsToken={smsToken}
          onPhoneChange={phoneStringChange}
          onPassportIdChange={setPassportIdString}
          switchAuth={switchAuthToggle}
          error={smsCodeError ? true : undefined}
          businessInfo={businessInfo}
          country={country}
        />
      ) : (
        <LoginPasswordForm
          login={login}
          phone={phoneString}
          country={country}
          onPhoneChange={phoneStringChange}
          switchAuth={switchAuthToggle}
          error={loginError ? true : undefined}
          businessInfo={businessInfo}
        />
      )}
    </>
  );
}
