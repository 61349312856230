import React from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { IsLoggedIn } from 'pages/IsLoggedIn';
import { AuthProvider, ClientContextProvider, ContentDirection, WidgetModal } from 'components';

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <AuthProvider>
        <ClientContextProvider>
          <ContentDirection />
          <IsLoggedIn />
          <WidgetModal />
        </ClientContextProvider>
      </AuthProvider>
    </HelmetProvider>
  );
};

export default App;
